import '../Global.css';
import AboutMe from './AboutMe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons'

const Home = () => {

    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = "//forms.aweber.com/form/97/531112897.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, "script", "aweber-wjs-5vslnxv7a"));


    return (
        <>
            <main>
                <div>
                    <div className='format'>
                        <AboutMe />
                        <h1>Receive a <span>FREE GUIDE </span>"5 Tips for Stopping Separation Anxiety"</h1>
                        <h1>AND a <span>FREE </span>Training Workshop Video from K9 Training Institute!</h1>
                        <h1><span>SIGN UP BELOW...</span></h1>
                        <div className='thumbnail'>
                            <img className='ebook' src='/SA-thumb.jpeg' alt='5 Tips for Stopping Separation Anxiety Thumbnail' />
                            <img className='video' src='/K9Video.png' alt='Training Workshop Video Thumbnail' />
                        </div>
                    </div>
                    <div className="format">
                        <div className="AW-Form-531112897"></div>
                    </div>
                    <div className='format'>
                        <h2>Please Donate to my GoFundMe:</h2>
                    </div>
                    <div>
                        <div className='thumbnail'>
                            <div className='format'>
                                <p>For Donations to My Local Shelter<br />in Memory of Hitch</p>
                                <a href='https://gofund.me/63d7ec99' target='_blank' rel='noreferrer'>CLICK HERE</a>
                                <img className='ebook' src='./Hitch.jpg' alt='Hitch'></img>
                            </div>
                            <div className='format'>
                                <p>For Donations to Help Cover<br /> Costs of my Service Dog</p>
                                <a href='https://gofund.me/020abd42' target='_blank' rel='noreferrer'>CLICK HERE</a>
                                <img className='ebook' src='./Zoey.jpeg' alt='Zoey'></img>
                            </div>
                        </div>
                    </div>
                    <div className='format'>
                        {/* <h3>For more Information on the K9 Training Institute:</h3>
                        <iframe className='video' src="https://www.youtube.com/embed/SddSK0Q_gdc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/SddSK0Q_gdc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
                    </div>
                    <div className='format icon'>
                        <p>Social Media:</p>
                        <span className='social'>
                            <a href="https://www.tiktok.com/@fcm.me"><FontAwesomeIcon icon={faTiktok} size="2xl" /></a>
                            <a href='https://www.facebook.com/profile.php?id=100091278768486'><FontAwesomeIcon icon={faFacebook} size="2xl" /></a>
                            <a href='https://www.instagram.com/affiliatedogmom'><FontAwesomeIcon icon={faInstagram} size="2xl" /></a>
                            <a href='https://www.youtube.com/@AffiliateDogMom'><FontAwesomeIcon icon={faYoutube} size="2xl" /></a>
                        </span>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Home