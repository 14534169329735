import React from 'react';
//import { Link } from "react-router-dom";
import '../Global.css';
// import Footer from './Footer';
import Header from './Header';

const AboutMe = () => {

    return (
        <>
            <main>
            <Header/>
                <div>
                    <div className='format'>
                        <h2>A Little About Me:</h2>
                        {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/e1aACC1eX6c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
                        <iframe className='video' src="https://www.youtube.com/embed/e1aACC1eX6c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        <h2>(Scroll down for FREE GIFTS!)</h2>
                    </div>
                </div>
                <div className='format'>
                    {/* <Footer/> */}
                </div>
            </main>
        </>
    )
}



export default AboutMe