import React from 'react';
//import { Link } from "react-router-dom";
import '../Global.css';

const Header = () => {

    return (
        <>
            <main>
                <div className='format'>
                    <img src="/ADM-Logo-Grey.jpeg" alt="Affiliate Dog Mom Logo" className='logo' />
                </div>
            </main>
        </>
    )
}

export default Header