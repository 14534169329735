import React from 'react';
import { Link } from "react-router-dom";
import '../FreeGifts.css';
import Header from './Header';
import Footer from './Footer';

const FreeGifts = () => {

    return (
        <>
        <Header/>
            <main>
                <div>
                    <div className='format'>
                        <h1><span className='gift'>DOWNLOAD PAGE...</span></h1><h1>Download your FREE EBOOK of "1000 Best Dog Training Secrets",</h1>
                        <h1>a FREE GUIDE "5 Tips for Stopping Separation Anxiety"</h1>
                        <h1>AND a FREE Training Workshop Video from K9 Training Institute!</h1>
                        <h1>Click the Images Below to Download...</h1>
                        <div className='thumbnail'>
                        <Link to={"https://drive.google.com/file/d/1HnTuE3K7MGPROHdeTsXcT-yEAFok_4lq/view?usp=share_link"}>
                            <img className='ebook' src='/1000BestDogTrainingSecrets.png' alt='Best Dog Training Secrets Thumbnail' />
                        </Link>
                        <Link to={"https://drive.google.com/file/d/1NaFV3wAWNW1wniKbYQ7pHNQkNqGNMtWI/view?usp=share_link"}>
                            <img className='ebook' src='/SA-thumb.jpeg' alt='5 Tips for Stopping Separation Anxiety Thumbnail' />
                        </Link>
                        <Link to={"https://k9ti.org/r/funkycoldmed/free-workshop/"}>
                            <img className='video' src='/K9Video.png' alt='Training Workshop Video Thumbnail' />
                        </Link>
                        </div>
                    </div>
                </div>
            </main>
            <Footer/>
        </>
    )
}



export default FreeGifts