import React from 'react';
//import { Link } from "react-router-dom";
import '../Global.css';

const Contact = () => {

    return (
        <>
            <main>
                <div>
                    <div className='format'>
                        <div>
                            <img src="/ADM-Logo-Grey.jpeg" alt="Affiliate Dog Mom Logo" width={800} />
                        </div>
                    </div>
                    <div className='format'>
                    </div>
                    <div className='format'>
                    </div>
                    <div className="AW-Form-535281975"></div>
                    <p>© 2023 Affiliate Dog Mom, LLC</p>
                </div>
            </main>
        </>
    )
}



export default Contact