import React from 'react';
import { Link } from "react-router-dom";
import '../Global.css';

const Footer = () => {

    return (
        <>
            <div className='format'>
                <p>There are affiliate links on this page, meaning, at no additional cost to you, I will be compensated if you click through and take action.</p>
                <p>© 2023 Affiliate Dog Mom, LLC</p>
                <Link to={"https://docs.google.com/document/d/15Pql0pY9mYXOCrtXLSLiuva9lxSzqFIb/edit?usp=share_link&ouid=100915159961896763144&rtpof=true&sd=true"}>Privacy Policy</Link>
            </div>
        </>
    )
}

export default Footer